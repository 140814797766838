@import './animate.min.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
.field_with_errors {
  @apply h-full w-full;
}
.field_with_errors input, .field_with_errors textarea {
  @apply border-red-300 bg-red-200 ring-red-100;
}

.mainDiv {
  position: absolute;
  top: 200px;
  left: 300px;
}

.textarea-height {
  height: 220px;
}

.tab-switcher {
  width: 100%;
  display: block;
  margin-top: 25px;
  margin-bottom: 0px;
}

.tab-switcher ul li {
  display: inline;
  padding: 10px;
  background-color: #111827;
  color: white;
  margin: 4px;
  cursor: pointer;
}

.tab-switcher ul li.selected {
  background-color: #6b9080;
}

.tab-switcher ul li:hover {
  opacity: 0.8;
  color: #111827;
  background-color: #86a397;
}

.results-days {
  color: #6b9080;
}

.durationSelect {
  margin-top: 30px;
  margin-bottom: -55px;
}

.custom-table-view {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-table-view td {
  border: 2px solid #e5e7eb;
  text-align: left;
  padding: 8px;
}

.custom-table-view tr:hover {
  background-color: #e5e7eb !important;
  color: #111827 !important;
}

.custom-table-view .header-tbl td {
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  padding: 8px;
  background-color: #e5e7eb;
  color: #111827;
}

.custom-table-view .header-tbl td:last-child {
  text-align: right;
}

.form-area {
  min-height: 1000px;
  background-color: #f3f4f6;
  margin-bottom: 10px;
}

.sidebar-header {
  padding-left: 0px !important;
  margin-left: 10px;
  font-size: 28px !important;
}

.form-step7-height {
  min-height: 1080px !important;
}

.form2-height {
  min-height: 880px;
}

.z-index {
  z-index: 99999999999999 !important;
}

.z-index-full {
  z-index: 99999999 !important;
}

.alert-close {
  margin-bottom: -30px;
}

.dropdown-options {
  height: 0px !important;
  position: relative;
  top: -60px;
  margin-bottom: -64px;
  width: 180px;
}

.dropdown-options-multiple {
  left: -145px;
}

.dropdown-options-ca {
  left: -385px;
}

.dropdown-options-ca-option {
  left: -180px;
}

.dropdown-options-ca-option-admin {
  left: -370px;
}

.dropdown-options-ca-option-multiple {
  left: -370px;
}

.dropdown-options div {
  background-color: #a4c3b2;
}

.filter-button {
  display: block;
  float: left;
  font-size: 14px;
  padding: 6px;
  margin: 2px 2px 10px 2px;
}

.filter-selected {
  background-color: #6b9080;
}

.filter-area {
  min-width: 300px;
}

.ca-table-big {
  min-height: 134px;
  max-height: 330px;
}

.ca-table-big-mobile {
  height: 210px;
}

.ca-table {
  min-height: 126px;
  max-height: 250px;
}

.block-float-left {
  float: left;
}

.dropdown-options-subca-admin {
  top: -70px;
  left: -210px;
}

@media (max-width: 800px) {
  .dropdown-options {
    top: -130px;
    width: 90vw !important;
    left: -10vw !important;
  }

  .dropdown-options-subca {
    top: -70px;
  }

  .dropdown-options-subca-admin {
    top: -70px;
    left: -210px !important;
  }

  .dropdown-options-ca-option-admin {
    left: -240px !important;
    top: -70px;
  }

  .mobile-row {
    width: 79vw;
  }

  .block-float-left {
    float: none;
  }

  .filter-button {
    font-size: 11px;
  }

  .mobile-ca-table {
    height: 280px;
    overflow-y: scroll;
    display: block;
    overflow-x: hidden;
  }

  .dropdown-options-ca-option {
    top: -185px !important;
    left: -41vw !important;
  }
}
@media (min-width: 360px) and (max-width: 380px) {
  .dropdown-options-ca-option {
    top: -185px !important;
    left: -55vw !important;
  }

  .filter-button {
    font-size: 10px;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .mobile-ca-table {
    height: 280px;
    overflow-y: scroll;
    display: block;
    overflow-x: hidden;
  }

  .dropdown-options-ca-option {
    top: -185px !important;
    left: -29vw !important;
  }

  .dropdown-options {
    top: -130px;
    width: 30vw;
    left: 5vw;
  }

  .dropdown-options-subca {
    top: -70px;
  }

  .mobile-row {
    width: 60vw;
  }

  .block-float-left {
    float: none;
  }

  .filter-button {
    font-size: 12px;
  }
}